import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../layouts/header"
import Footer from "../components/custom-components/FooterCream"
import Sidebar from "../components/custom-components/sidebar-custom"

import "../css/custom-css/custom-news.css"

import bg from "../images/news-event/news-event-banner.webp"
import FloatingBtn from "../components/custom-components/floatingBtn"

const NewsEventTemplate = ({ children, handleInputChange }) => {
  return (
    <>
      <Header />
      <FloatingBtn />
      <div class="page-content bg-white">
        <div
          class="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div class="container">
            <div class="dlab-bnr-inr-entry">
              <h1 class="text-white">News and Events</h1>
              <div class="breadcrumb-row">
                <ul class="list-inline">
                  <li>
                    <Link to="/index">Home</Link>
                  </li>
                  <li>News and Events</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="content-area">
          {/* <div class="container containerCustom"> */}
          <div className="container" style={{ maxWidth: '95%' }}>
            {/* <a href="/"><button class="site-button white m-b50" type="button"><span class="site-button-inr"><i class="fa fa-arrow-left"></i>Back</span></button></a> */}

            <div class="row">
              <div class="col-lg-9 col-md-7 flexCustom">
                <div id="masonry" class="dlab-blog-grid-2">
                  {children}
                </div>
              </div>

              <Sidebar handleInputChange={handleInputChange} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default NewsEventTemplate
