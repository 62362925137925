import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import slugify from "slugify"
import { Link } from "gatsby"

const CardNewsEvent = ({ data }) => {
  // const slug = slugify(props.data.title,{lower:true})

  return (
    <div class="post card-container col-lg-4 col-md-12 col-sm-12">
      <div class="blog-post blog-grid blog-rounded blog-effect1 post-style-1">
        <div class="dlab-post-media dlab-img-effect">
          <a href={data.slug}>
            <GatsbyImage image={data.images.gatsbyImageData} alt="card-img" />
          </a>
        </div>
        <div
          // class="dlab-info p-a20 border-1 info-news"
          class="dlab-info p-a20 border-1 info-news"
          style={{ minHeight: "450px" }}
        >
          <div class="dlab-post-meta">
            <ul>
              <li class="post-date">
                {" "}
                <strong>{data.newsEventsDate}</strong>
              </li>
            </ul>
          </div>
          <div class="dlab-post-title">
            <h4 class="post-title">{data.title}</h4>
          </div>
          <div class="dlab-post-text">
            <p>{data.descSingkat}</p>
          </div>
          <div class="dlab-post-readmore">
            <a
              href={data.slug}
              title="READ MORE"
              rel="bookmark"
              class="site-button"
            >
              READ MORE
              <i class="ti-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardNewsEvent
