import React from 'react';
import { Link } from 'gatsby';
import { Accordion, AccordionItem } from 'react-sanfona';

import '../../css/custom-css/custom-sidebar.css';

const SidebarCustom = ({handleInputChange}) => {
    return (
        <div class="col-lg-3">
            <aside class="side-bar sticky-top">
                
                <div class="widget">
                    <h5 class="widget-title style-1">Search</h5>
                    <div class="search-bx style-1">
                        <form role="search" method="post">
                            <div class="input-group">
                                <input 
                                name="text" 
                                class="form-control" 
                                placeholder="Search" 
                                type="text"
                                onChange={handleInputChange}
                                 />
                                <span class="input-group-btn">
                                    <div class="fa-custom fa fa-search "></div>
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="widget widget_archive">
                    <h5 class="widget-title style-1">Categories List</h5>
                    <ul>
                        <li title="News">                           
                            <Link to={'../news'}>News</Link>
                        </li> 
                        <li title="Event">
                            <Link to={'../event'}>Event</Link>
                        </li>
                    </ul>
                </div>
                
            </aside>
        </div>
    );
}

export default SidebarCustom;